import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  OverallResults,
  Result,
  Results,
  ResultsAmount,
  ResultsItemsInner,
} from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type ResultState = {
  result: Result | undefined;
  amount: ResultsAmount | undefined;
  results: Results | undefined;
  highResults: ResultsItemsInner[];
  overallResults: OverallResults | undefined;
  status: {
    fetch: AsyncStatus;
    fetchAmount: AsyncStatus;
    bulkFetch: AsyncStatus;
    fetchHighResults: AsyncStatus;
    fetchOverallResults: AsyncStatus;
  };
};

const initialState: ResultState = {
  result: undefined,
  amount: undefined,
  results: undefined,
  highResults: [],
  overallResults: undefined,
  status: {
    fetch: AsyncStatus.IDLE,
    fetchAmount: AsyncStatus.IDLE,
    bulkFetch: AsyncStatus.IDLE,
    fetchHighResults: AsyncStatus.IDLE,
    fetchOverallResults: AsyncStatus.IDLE,
  },
};

export const fetchResult = createAsyncThunk(
  'fetchResult',
  async ({ year, month, userId }: { year: number; month: number; userId: string }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getResult(year, month, userId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchResultsAmount = createAsyncThunk(
  'fetchResultsAmount',
  async (
    { year, month, fontId }: { year: number; month: number; fontId: number | undefined },
    thunkAPI
  ) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getResultsAmount(year, month, fontId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchResults = createAsyncThunk(
  'fetchResults',
  async (
    {
      year,
      month,
      rankId,
      pageId,
      fontId,
    }: { year: number; month: number; rankId: number | string; pageId: number; fontId?: number },
    thunkAPI
  ) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getResults(year, month, rankId, pageId, fontId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchHighScoreResults = createAsyncThunk(
  'fetchHighScoreResults',
  async ({ year, month, fontId }: { year: number; month: number; fontId: number }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getHighResults(year, month, fontId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchOverallResults = createAsyncThunk(
  'fetchOverallResults',
  async (
    {
      year,
      month,
      rankId,
      pageId,
    }: { year: number; month: number; rankId: number; pageId: number },
    thunkAPI
  ) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getOverallResults(year, month, rankId, pageId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    clearStatus: (state: ResultState) => {
      state.status.fetch = AsyncStatus.IDLE;
      state.status.bulkFetch = AsyncStatus.IDLE;
      state.status.fetchAmount = AsyncStatus.IDLE;
      state.status.fetchHighResults = AsyncStatus.IDLE;
      state.status.fetchOverallResults = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResult.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchResult.fulfilled, (state, { payload }: { payload: Result }) => {
        state.result = {
          ...payload,
          // 臨書を配列最後尾にする
          assignments: payload.assignments?.sort((a) => {
            if (a.font?.id === FontTypeIDs.RINSHO) return 1;
            return -1;
          }),
        };
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchResult.rejected, (state) => {
        const beforeState = state.status.fetch;

        state.status.fetch = AsyncStatus.FAILED;
        // 2重にリクエストを飛ばしていると1個目の後のステータスに影響を受けてページを開くタイミングでFAILEDのままになる
        // FAILEDだとリダイレクトする処理にしているため困る
        // なのでLOADINGからではなくIDLEからFAILEDになった場合は1個目の通信でclearStatusされているのでリセット
        if (beforeState === AsyncStatus.IDLE) {
          state.status.fetch = AsyncStatus.IDLE;
        }
      })
      .addCase(fetchResultsAmount.pending, (state) => {
        state.status.fetchAmount = AsyncStatus.LOADING;
      })
      .addCase(fetchResultsAmount.fulfilled, (state, { payload }) => {
        state.amount = payload;
        state.status.fetchAmount = AsyncStatus.SUCCESS;
      })
      .addCase(fetchResultsAmount.rejected, (state) => {
        state.status.fetchAmount = AsyncStatus.FAILED;
      })
      .addCase(fetchResults.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(fetchResults.fulfilled, (state, { payload }: { payload: Results }) => {
        state.results = payload;
        state.status.bulkFetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchResults.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      })
      .addCase(fetchHighScoreResults.pending, (state) => {
        state.status.fetchHighResults = AsyncStatus.LOADING;
      })
      .addCase(
        fetchHighScoreResults.fulfilled,
        (state, { payload }: { payload: ResultsItemsInner[] }) => {
          state.highResults = payload;
          state.status.fetchHighResults = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchHighScoreResults.rejected, (state) => {
        state.status.fetchHighResults = AsyncStatus.FAILED;
      })
      .addCase(fetchOverallResults.pending, (state) => {
        state.status.fetchOverallResults = AsyncStatus.LOADING;
      })
      .addCase(fetchOverallResults.fulfilled, (state, { payload }: { payload: OverallResults }) => {
        state.overallResults = payload;
        state.status.fetchOverallResults = AsyncStatus.SUCCESS;
      })
      .addCase(fetchOverallResults.rejected, (state) => {
        state.status.fetchOverallResults = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = resultSlice.actions;

export const resultSelector = (state: RootState) => state.result;

export default resultSlice.reducer;
