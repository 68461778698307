import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
dayjs.extend(isBetween);
import 'dayjs/locale/ja';

import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';
import { RegisterForm, URL } from '@/types';

export default function SignUpRegistration() {
  const { t } = useTranslation();
  const { account } = useAppSelector(authSelector);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { handleSubmit, setValue } = useForm<RegisterForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (state) {
      setValue('connect_user_id', state.connect_user_id);
      setValue('connect_instance_id', state.connect_instance_id);
      setValue('aws_account_id', state.aws_account_id);
      setValue('iam_role_arn_for_cdk', state.iam_role_arn_for_cdk);
    }
  }, [state]);

  const onSubmitForm = ({ ...others }: RegisterForm) => {
    // Create実行
    navigate(URL.SIGN_UP_CONFIRMATION, {
      state: {
        ...others,
        email: account?.email,
      },
    });
  };

  return (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:sign_up:title')}
      </Typography>

      <Box component="form" noValidate onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ mb: 6 }}>
          <label>
            しばらくお待ち下さい
            <br />
            完了しましたらメールでご連絡します
          </label>
        </Stack>
      </Box>
    </Container>
  );
}
