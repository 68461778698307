import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User, UsersApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig, ApiConfig2 } from '@/utils/apiConfig';

type UserState = {
  users: User[];
  status: { fetch: AsyncStatus; create: AsyncStatus; update: AsyncStatus };
  error?: string;
};

const initialState: UserState = {
  users: [],
  status: {
    fetch: AsyncStatus.IDLE,
    create: AsyncStatus.IDLE,
    update: AsyncStatus.IDLE,
  },
  error: '',
};

export const fetchUsers = createAsyncThunk('fetchUsers', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    const { data } = await usersApi.getUsers();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createUser = createAsyncThunk('createUser', async (user: User, thunkAPI) => {
  try {
    const apiConfig = ApiConfig2();
    const usersApi = new UsersApi(apiConfig);
    await usersApi.createUser(user);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateUser = createAsyncThunk('updateUser', async (user: User, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    await usersApi.updateUser(user.id, user);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStatus: (state: UserState) => {
      state.status.fetch = AsyncStatus.IDLE;
      state.status.update = AsyncStatus.IDLE;
      state.status.create = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }: { payload: User[] }) => {
        state.users = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      })
      .addCase(createUser.pending, (state) => {
        state.status.create = AsyncStatus.LOADING;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.status.create = AsyncStatus.SUCCESS;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status.create = AsyncStatus.FAILED;
        const errorResponse = action.payload as { response: { data: string } };
        state.error = errorResponse.response.data;
      })
      .addCase(updateUser.pending, (state) => {
        state.status.update = AsyncStatus.LOADING;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status.update = AsyncStatus.SUCCESS;
      })
      .addCase(updateUser.rejected, (state) => {
        state.status.update = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
