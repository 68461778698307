/* tslint:disable */
/* eslint-disable */
/**
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * ユーザーが本登録済みかどうか
 * @export
 * @interface CompletedSignUp
 */
export interface CompletedSignUp {
  /**
   *
   * @type {boolean}
   * @memberof CompletedSignUp
   */
  completed_sign_up?: boolean;
}
/**
 * 登録する際にリクエストに含めるユーザー情報
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof CreateUser
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof CreateUser
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof CreateUser
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUser
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  parent_first_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUser
   */
  completed_sign_up?: boolean;
}
/**
 *
 * @export
 * @interface Font
 */
export interface Font {
  /**
   *
   * @type {FontFont}
   * @memberof Font
   */
  font: FontFont;
}
/**
 *
 * @export
 * @interface FontFont
 */
export interface FontFont {
  /**
   *
   * @type {number}
   * @memberof FontFont
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FontFont
   */
  name: string;
}
/**
 *
 * @export
 * @interface FontId
 */
export interface FontId {
  /**
   *
   * @type {number}
   * @memberof FontId
   */
  font_id?: number;
}
/**
 *
 * @export
 * @interface FontsInner
 */
export interface FontsInner {
  /**
   *
   * @type {number}
   * @memberof FontsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FontsInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FontsInner
   */
  course_type?: number;
}
/**
 *
 * @export
 * @interface GetAssignments200ResponseInner
 */
export interface GetAssignments200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof GetAssignments200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof GetAssignments200ResponseInner
   */
  font_id?: number;
  /**
   *
   * @type {string}
   * @memberof GetAssignments200ResponseInner
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface GetAssignmentsUsername200ResponseInner
 */
export interface GetAssignmentsUsername200ResponseInner {
  /**
   * 課題ID
   * @type {number}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  assignment_id?: number;
  /**
   * ユーザー名
   * @type {string}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  nickname?: string;
  /**
   * 課題が採点されたらtrue
   * @type {boolean}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  is_reviewed?: boolean;
}
/**
 * @type GetResultsRankIdParameter
 * @export
 */
export type GetResultsRankIdParameter = number | string;

/**
 *
 * @export
 * @interface Handbook
 */
export interface Handbook {
  /**
   *
   * @type {FontFont}
   * @memberof Handbook
   */
  font: FontFont;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  grade_id: number | null;
  /**
   *
   * @type {HandbookAllOfDemonstration}
   * @memberof Handbook
   */
  demonstration: HandbookAllOfDemonstration;
  /**
   *
   * @type {HandbookAllOfExplanation}
   * @memberof Handbook
   */
  explanation: HandbookAllOfExplanation;
}
/**
 *
 * @export
 * @interface HandbookAllOf
 */
export interface HandbookAllOf {
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  grade_id?: number | null;
  /**
   *
   * @type {HandbookAllOfDemonstration}
   * @memberof HandbookAllOf
   */
  demonstration?: HandbookAllOfDemonstration;
  /**
   *
   * @type {HandbookAllOfExplanation}
   * @memberof HandbookAllOf
   */
  explanation?: HandbookAllOfExplanation;
}
/**
 *
 * @export
 * @interface HandbookAllOfDemonstration
 */
export interface HandbookAllOfDemonstration {
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfDemonstration
   */
  video_urls: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfDemonstration
   */
  image_urls: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HandbookAllOfDemonstration
   */
  comment: string | null;
}
/**
 *
 * @export
 * @interface HandbookAllOfExplanation
 */
export interface HandbookAllOfExplanation {
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfExplanation
   */
  video_urls: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfExplanation
   */
  image_urls: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HandbookAllOfExplanation
   */
  comment: string | null;
}
/**
 *
 * @export
 * @interface HandbooksAvailTermsInner
 */
export interface HandbooksAvailTermsInner {
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  term_status?: number;
}
/**
 *
 * @export
 * @interface HighResultsInner
 */
export interface HighResultsInner {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof HighResultsInner
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {ResultsItemsInnerFont}
   * @memberof HighResultsInner
   */
  font?: ResultsItemsInnerFont;
  /**
   *
   * @type {ResultsItemsInnerRankOrders}
   * @memberof HighResultsInner
   */
  rank_orders?: ResultsItemsInnerRankOrders;
  /**
   * scoresの合計point
   * @type {number}
   * @memberof HighResultsInner
   */
  score_amount?: number;
  /**
   * 課題の画像URL
   * @type {string}
   * @memberof HighResultsInner
   */
  image_url?: string;
  /**
   *
   * @type {ResultsItemsInnerRank}
   * @memberof HighResultsInner
   */
  rank?: ResultsItemsInnerRank;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof HighResultsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 * 成績非公開設定
 * @export
 * @interface IsPrivate
 */
export interface IsPrivate {
  /**
   *
   * @type {boolean}
   * @memberof IsPrivate
   */
  is_private?: boolean;
}
/**
 *
 * @export
 * @interface LatestResults
 */
export interface LatestResults {
  /**
   * 年
   * @type {number}
   * @memberof LatestResults
   */
  year?: number;
  /**
   * 月
   * @type {number}
   * @memberof LatestResults
   */
  month?: number;
  /**
   *
   * @type {Array<LatestResultsAssignmentsInner>}
   * @memberof LatestResults
   */
  assignments?: Array<LatestResultsAssignmentsInner>;
}
/**
 *
 * @export
 * @interface LatestResultsAssignmentsInner
 */
export interface LatestResultsAssignmentsInner {
  /**
   * 書体ID
   * @type {number}
   * @memberof LatestResultsAssignmentsInner
   */
  font_id?: number | null;
  /**
   * 最新で採点された課題の画像URL
   * @type {string}
   * @memberof LatestResultsAssignmentsInner
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 *
 * @export
 * @interface OverallResult
 */
export interface OverallResult {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof OverallResult
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {Array<OverallResultResultsInner>}
   * @memberof OverallResult
   */
  results?: Array<OverallResultResultsInner>;
}
/**
 *
 * @export
 * @interface OverallResultResultsInner
 */
export interface OverallResultResultsInner {
  /**
   *
   * @type {OverallResultResultsInnerFont}
   * @memberof OverallResultResultsInner
   */
  font?: OverallResultResultsInnerFont;
  /**
   *
   * @type {ResultsItemsInnerRankCurrent}
   * @memberof OverallResultResultsInner
   */
  rank?: ResultsItemsInnerRankCurrent;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof OverallResultResultsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 *
 * @export
 * @interface OverallResultResultsInnerFont
 */
export interface OverallResultResultsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof OverallResultResultsInnerFont
   */
  id: number | null;
  /**
   * 書体名
   * @type {string}
   * @memberof OverallResultResultsInnerFont
   */
  name: string;
}
/**
 *
 * @export
 * @interface OverallResults
 */
export interface OverallResults {
  /**
   * 総合段級位の総数
   * @type {number}
   * @memberof OverallResults
   */
  total?: number;
  /**
   *
   * @type {Array<OverallResult>}
   * @memberof OverallResults
   */
  items?: Array<OverallResult>;
}
/**
 *
 * @export
 * @interface PostAssignmentRequest
 */
export interface PostAssignmentRequest {
  /**
   *
   * @type {string}
   * @memberof PostAssignmentRequest
   */
  user_id: string;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  handbook_id: number;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  month?: number;
  /**
   * 画像ファイル
   * @type {File}
   * @memberof PostAssignmentRequest
   */
  file: File;
}
/**
 *
 * @export
 * @interface RankArchivesInner
 */
export interface RankArchivesInner {
  /**
   *
   * @type {RankArchivesInnerFont}
   * @memberof RankArchivesInner
   */
  font?: RankArchivesInnerFont;
  /**
   *
   * @type {Array<RankArchivesInnerResultsInner>}
   * @memberof RankArchivesInner
   */
  results?: Array<RankArchivesInnerResultsInner>;
}
/**
 *
 * @export
 * @interface RankArchivesInnerFont
 */
export interface RankArchivesInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof RankArchivesInnerFont
   */
  id?: number | null;
  /**
   * 書体名
   * @type {string}
   * @memberof RankArchivesInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface RankArchivesInnerResultsInner
 */
export interface RankArchivesInnerResultsInner {
  /**
   * 月
   * @type {number}
   * @memberof RankArchivesInnerResultsInner
   */
  month?: number;
  /**
   *
   * @type {RankArchivesInnerResultsInnerRank}
   * @memberof RankArchivesInnerResultsInner
   */
  rank?: RankArchivesInnerResultsInnerRank;
}
/**
 *
 * @export
 * @interface RankArchivesInnerResultsInnerRank
 */
export interface RankArchivesInnerResultsInnerRank {
  /**
   * 該当年月の段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof RankArchivesInnerResultsInnerRank
   */
  order?: number;
  /**
   * 課題提出時の段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof RankArchivesInnerResultsInnerRank
   */
  pre_rank_order?: number;
}
/**
 *
 * @export
 * @interface RankWithFont
 */
export interface RankWithFont {
  /**
   *
   * @type {number}
   * @memberof RankWithFont
   */
  font_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof RankWithFont
   */
  rank_name?: string;
}
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {ResultUser}
   * @memberof Result
   */
  user?: ResultUser;
  /**
   *
   * @type {ResultOverallRank}
   * @memberof Result
   */
  overall_rank?: ResultOverallRank;
  /**
   *
   * @type {Array<ResultAssignmentsInner>}
   * @memberof Result
   */
  assignments?: Array<ResultAssignmentsInner>;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInner
 */
export interface ResultAssignmentsInner {
  /**
   *
   * @type {ResultAssignmentsInnerFont}
   * @memberof ResultAssignmentsInner
   */
  font?: ResultAssignmentsInnerFont;
  /**
   * 50点満点の点数
   * @type {number}
   * @memberof ResultAssignmentsInner
   */
  amount?: number;
  /**
   * 提出した課題画像
   * @type {string}
   * @memberof ResultAssignmentsInner
   */
  image_url?: string;
  /**
   * 審査所感
   * @type {Array<string>}
   * @memberof ResultAssignmentsInner
   */
  comments?: Array<string>;
  /**
   *
   * @type {ResultAssignmentsInnerRankOrder}
   * @memberof ResultAssignmentsInner
   */
  rank_order?: ResultAssignmentsInnerRankOrder;
  /**
   *
   * @type {ResultAssignmentsInnerRank}
   * @memberof ResultAssignmentsInner
   */
  rank?: ResultAssignmentsInnerRank;
  /**
   * 採点項目の点数の配列
   * @type {Array<ResultAssignmentsInnerScoresInner>}
   * @memberof ResultAssignmentsInner
   */
  scores?: Array<ResultAssignmentsInnerScoresInner>;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerFont
 */
export interface ResultAssignmentsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof ResultAssignmentsInnerFont
   */
  id?: number;
  /**
   * 書体名
   * @type {string}
   * @memberof ResultAssignmentsInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerRank
 */
export interface ResultAssignmentsInnerRank {
  /**
   *
   * @type {ResultAssignmentsInnerRankCurrent}
   * @memberof ResultAssignmentsInnerRank
   */
  current?: ResultAssignmentsInnerRankCurrent;
  /**
   *
   * @type {ResultAssignmentsInnerRankPrevious}
   * @memberof ResultAssignmentsInnerRank
   */
  previous?: ResultAssignmentsInnerRankPrevious;
  /**
   * 昇段フラグ
   * @type {boolean}
   * @memberof ResultAssignmentsInnerRank
   */
  is_rank_up?: boolean;
}
/**
 * 採点後の書体段級位
 * @export
 * @interface ResultAssignmentsInnerRankCurrent
 */
export interface ResultAssignmentsInnerRankCurrent {
  /**
   * 採点後の書体段級位ID
   * @type {number}
   * @memberof ResultAssignmentsInnerRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultAssignmentsInnerRankCurrent
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerRankOrder
 */
export interface ResultAssignmentsInnerRankOrder {
  /**
   *
   * @type {ResultAssignmentsInnerRankOrderSameRankOrder}
   * @memberof ResultAssignmentsInnerRankOrder
   */
  same_rank_order?: ResultAssignmentsInnerRankOrderSameRankOrder;
  /**
   *
   * @type {ResultAssignmentsInnerRankOrderWholeOrder}
   * @memberof ResultAssignmentsInnerRankOrder
   */
  whole_order?: ResultAssignmentsInnerRankOrderWholeOrder;
}
/**
 * 同位順位
 * @export
 * @interface ResultAssignmentsInnerRankOrderSameRankOrder
 */
export interface ResultAssignmentsInnerRankOrderSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderSameRankOrder
   */
  order?: number;
  /**
   * 同位順位の母数(提出時同じ段級位のユーザーかつ同じ手本に対する提出数)
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderSameRankOrder
   */
  total?: number;
}
/**
 * 全体順位
 * @export
 * @interface ResultAssignmentsInnerRankOrderWholeOrder
 */
export interface ResultAssignmentsInnerRankOrderWholeOrder {
  /**
   * 全体順位
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderWholeOrder
   */
  order?: number;
  /**
   * 全体順位の母数(同じ手本に対する提出数)
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderWholeOrder
   */
  total?: number;
}
/**
 * 採点前の段級位
 * @export
 * @interface ResultAssignmentsInnerRankPrevious
 */
export interface ResultAssignmentsInnerRankPrevious {
  /**
   * 採点前の段級位ID
   * @type {number}
   * @memberof ResultAssignmentsInnerRankPrevious
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultAssignmentsInnerRankPrevious
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerScoresInner
 */
export interface ResultAssignmentsInnerScoresInner {
  /**
   *
   * @type {ResultAssignmentsInnerScoresInnerViewpoint}
   * @memberof ResultAssignmentsInnerScoresInner
   */
  viewpoint?: ResultAssignmentsInnerScoresInnerViewpoint;
  /**
   * 評価項目に対する点数
   * @type {number}
   * @memberof ResultAssignmentsInnerScoresInner
   */
  point?: number;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerScoresInnerViewpoint
 */
export interface ResultAssignmentsInnerScoresInnerViewpoint {
  /**
   * 評価項目ID
   * @type {number}
   * @memberof ResultAssignmentsInnerScoresInnerViewpoint
   */
  id?: number;
  /**
   * 評価項目名
   * @type {string}
   * @memberof ResultAssignmentsInnerScoresInnerViewpoint
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultOverallRank
 */
export interface ResultOverallRank {
  /**
   *
   * @type {ResultOverallRankCurrent}
   * @memberof ResultOverallRank
   */
  current?: ResultOverallRankCurrent;
  /**
   *
   * @type {ResultOverallRankPrevious}
   * @memberof ResultOverallRank
   */
  previous?: ResultOverallRankPrevious;
}
/**
 * 採点後の総合段級位
 * @export
 * @interface ResultOverallRankCurrent
 */
export interface ResultOverallRankCurrent {
  /**
   * 採点後の総合段級位ID
   * @type {number}
   * @memberof ResultOverallRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultOverallRankCurrent
   */
  name?: string;
}
/**
 * 1つ前の段級位
 * @export
 * @interface ResultOverallRankPrevious
 */
export interface ResultOverallRankPrevious {
  /**
   * 1つ前の段級位ID
   * @type {number}
   * @memberof ResultOverallRankPrevious
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultOverallRankPrevious
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultUser
 */
export interface ResultUser {
  /**
   *
   * @type {string}
   * @memberof ResultUser
   */
  nickname?: string;
  /**
   *
   * @type {string}
   * @memberof ResultUser
   */
  birthday?: string;
}
/**
 *
 * @export
 * @interface Results
 */
export interface Results {
  /**
   * 同課題&同位順位の総数
   * @type {number}
   * @memberof Results
   */
  total?: number;
  /**
   *
   * @type {Array<ResultsItemsInner>}
   * @memberof Results
   */
  items?: Array<ResultsItemsInner>;
}
/**
 *
 * @export
 * @interface ResultsAmount
 */
export interface ResultsAmount {
  /**
   * ユーザーの段級位を表現する数字 (10級 → 1, ~, 師範 → 21) ※未提出であればnull
   * @type {number}
   * @memberof ResultsAmount
   */
  user_rank_order?: number | null;
  /**
   * 段級位ごとの人数に関する配列 ※段級位が低い順(ranks.order降順)
   * @type {Array<ResultsAmountRankInner>}
   * @memberof ResultsAmount
   */
  rank?: Array<ResultsAmountRankInner>;
  /**
   *
   * @type {ResultsAmountAmount}
   * @memberof ResultsAmount
   */
  amount?: ResultsAmountAmount;
}
/**
 *
 * @export
 * @interface ResultsAmountAmount
 */
export interface ResultsAmountAmount {
  /**
   * 師範・準師範の段級位取得者数
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  shihan?: number;
  /**
   * 級 取得者数 (10級~1級)
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  kyu?: number;
  /**
   * 段 取得者数 (初段~9段)
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  dan?: number;
}
/**
 *
 * @export
 * @interface ResultsAmountRankInner
 */
export interface ResultsAmountRankInner {
  /**
   * 段級位ID
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  id?: number;
  /**
   * 段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  order?: number;
  /**
   * 該当段級位の人数 (書体であれば、課題提出者のみ数に含む、総合段級位は全未提出者を数に含める)
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInner
 */
export interface ResultsItemsInner {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof ResultsItemsInner
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {ResultsItemsInnerFont}
   * @memberof ResultsItemsInner
   */
  font?: ResultsItemsInnerFont;
  /**
   *
   * @type {ResultsItemsInnerRankOrders}
   * @memberof ResultsItemsInner
   */
  rank_orders?: ResultsItemsInnerRankOrders;
  /**
   * scoresの合計point
   * @type {number}
   * @memberof ResultsItemsInner
   */
  score_amount?: number;
  /**
   * 課題の画像URL
   * @type {string}
   * @memberof ResultsItemsInner
   */
  image_url?: string;
  /**
   * 審査所感
   * @type {Array<string>}
   * @memberof ResultsItemsInner
   */
  comments?: Array<string>;
  /**
   *
   * @type {ResultsItemsInnerRank}
   * @memberof ResultsItemsInner
   */
  rank?: ResultsItemsInnerRank;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof ResultsItemsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 * 書体
 * @export
 * @interface ResultsItemsInnerFont
 */
export interface ResultsItemsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof ResultsItemsInnerFont
   */
  id?: number;
  /**
   * 書体名
   * @type {string}
   * @memberof ResultsItemsInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerRank
 */
export interface ResultsItemsInnerRank {
  /**
   *
   * @type {ResultsItemsInnerRankCurrent}
   * @memberof ResultsItemsInnerRank
   */
  current?: ResultsItemsInnerRankCurrent;
  /**
   *
   * @type {boolean}
   * @memberof ResultsItemsInnerRank
   */
  is_rank_up?: boolean;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerRankCurrent
 */
export interface ResultsItemsInnerRankCurrent {
  /**
   * 段級位ID
   * @type {number}
   * @memberof ResultsItemsInnerRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultsItemsInnerRankCurrent
   */
  name?: string;
}
/**
 * 順位の配列
 * @export
 * @interface ResultsItemsInnerRankOrders
 */
export interface ResultsItemsInnerRankOrders {
  /**
   *
   * @type {ResultsItemsInnerRankOrdersSameRankOrder}
   * @memberof ResultsItemsInnerRankOrders
   */
  same_rank_order?: ResultsItemsInnerRankOrdersSameRankOrder;
  /**
   *
   * @type {ResultsItemsInnerRankOrdersWholeOrder}
   * @memberof ResultsItemsInnerRankOrders
   */
  whole_order?: ResultsItemsInnerRankOrdersWholeOrder;
}
/**
 * 同位の総数と同位内での課題の順位
 * @export
 * @interface ResultsItemsInnerRankOrdersSameRankOrder
 */
export interface ResultsItemsInnerRankOrdersSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersSameRankOrder
   */
  order?: number;
  /**
   * 同一提出課題総数
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersSameRankOrder
   */
  total?: number;
}
/**
 * 全体の総数と全体内での課題の順位
 * @export
 * @interface ResultsItemsInnerRankOrdersWholeOrder
 */
export interface ResultsItemsInnerRankOrdersWholeOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersWholeOrder
   */
  order?: number;
  /**
   * 提出課題総数
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersWholeOrder
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerScoresInner
 */
export interface ResultsItemsInnerScoresInner {
  /**
   *
   * @type {ResultsItemsInnerScoresInnerViewpoint}
   * @memberof ResultsItemsInnerScoresInner
   */
  viewpoint?: ResultsItemsInnerScoresInnerViewpoint;
  /**
   * 得点
   * @type {number}
   * @memberof ResultsItemsInnerScoresInner
   */
  point?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerScoresInnerViewpoint
 */
export interface ResultsItemsInnerScoresInnerViewpoint {
  /**
   * 項目ID
   * @type {number}
   * @memberof ResultsItemsInnerScoresInnerViewpoint
   */
  id?: number;
  /**
   * 項目名
   * @type {string}
   * @memberof ResultsItemsInnerScoresInnerViewpoint
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerUser
 */
export interface ResultsItemsInnerUser {
  /**
   * ユーザーを識別するID
   * @type {string}
   * @memberof ResultsItemsInnerUser
   */
  id?: string;
  /**
   * ニックネーム
   * @type {string}
   * @memberof ResultsItemsInnerUser
   */
  name?: string;
}

/**
 * レスポンスで返すユーザー情報
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof User
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  parent_first_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_private?: boolean;
}
/**
 * ユーザー関連スキーマの共通部分
 * @export
 * @interface UserBase
 */
export interface UserBase {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof UserBase
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof UserBase
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof UserBase
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof UserBase
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  parent_first_name?: string;
}
/**
 *
 * @export
 * @interface UserBaseRolesInner
 */
export interface UserBaseRolesInner {
  /**
   *
   * @type {string}
   * @memberof UserBaseRolesInner
   */
  role_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserBaseRolesInner
   */
  display_name?: string;
}
/**
 * ユーザーのUUID
 * @export
 * @interface UserId
 */
export interface UserId {
  /**
   *
   * @type {string}
   * @memberof UserId
   */
  id?: string;
}
/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/userinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserInfoApiAxiosParamCreator(configuration);
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLoginUser(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Userinfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginUser(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserInfoApiFp(configuration);
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginUser(options?: any): AxiosPromise<Userinfo> {
      return localVarFp.getLoginUser(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
  /**
   * 自分のユーザー情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserInfoApi
   */
  public getLoginUser(options?: AxiosRequestConfig) {
    return UserInfoApiFp(this.configuration)
      .getLoginUser(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      createUser: CreateUser,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUser' is not null or undefined
      assertParamExists('createUser', 'createUser', createUser);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUser,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      createUser: CreateUser,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUser, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary 各段級位情報つきのユーザー情報リストを取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersWithRanks(
      courseType?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWithRanks>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersWithRanks(
        courseType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * ユーザー情報1件更新
     * @param {string} id ユーザーを識別するID
     * @param {UpdateUser} updateUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      id: string,
      updateUser: UpdateUser,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUser, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(createUser: CreateUser, options?: any): AxiosPromise<User> {
      return localVarFp.createUser(createUser, options).then((request) => request(axios, basePath));
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options?: any): AxiosPromise<Array<User>> {
      return localVarFp.getUsers(options).then((request) => request(axios, basePath));
    },
    /**
     * ユーザー情報1件更新
     * @param {string} id ユーザーを識別するID
     * @param {UpdateUser} updateUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, updateUser: UpdateUser, options?: any): AxiosPromise<User> {
      return localVarFp
        .updateUser(id, updateUser, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * ユーザー情報登録
   * @param {CreateUser} createUser
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(createUser: CreateUser, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .createUser(createUser, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 全ユーザー情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsers(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
