import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
dayjs.extend(isBetween);
import 'dayjs/locale/ja';

import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAccount from '@/hooks/useAccount';
import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';
import { RegisterForm, URL } from '@/types';

export default function SignUpRegistration() {
  const { t } = useTranslation();
  const { validationRules } = useAccount();
  const { account } = useAppSelector(authSelector);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm<RegisterForm>({
    mode: 'onChange',
  });
  const imageURL =
    'https://settings.console.saasus.io/static/images/integrations/aws-marketplace/cloudformation-launch-stack.png';
  const openNewWindow = () => {
    // 別ウィンドウを開く
    window.open(
      'https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://saasus-core-marketplacet-saasuscoremarketplacetem-1rfy7zh49m3q9.s3.ap-northeast-1.amazonaws.com/aws_marketplace.template&stackName=AWS-Marketplace-seller-Resource&param_ExternalID=80ddc03d-f0c4-4f13-aeb0-dd721eb19cd7&param_SaasusAccountID=822144277561&param_SNSTopicARN=a&param_SQSArn=arn:aws:sqs:ap-northeast-1:822144277561:SaaSus-Tenant-AwsMarketplace-AwsMarketplaceQueueF97B1E0E-H8ibDIltjGQW',
      '_blank'
    ); // 新しいウィンドウのURLを実際のURLに置き換えてください
  };

  useEffect(() => {
    if (state) {
      setValue('tenant_name', state.tenant_name);
      setValue('connect_user_id', state.connect_user_id);
      setValue('connect_instance_id', state.connect_instance_id);
      setValue('aws_account_id', state.aws_account_id);
      setValue('iam_role_arn_for_cdk', state.iam_role_arn_for_cdk);
    }
  }, [state]);

  const onSubmitForm = ({ ...others }: RegisterForm) => {
    // Create実行
    navigate(URL.SIGN_UP_CONFIRMATION, {
      state: {
        ...others,
        email: account?.email,
      },
    });
  };

  return (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:sign_up:title')}
      </Typography>

      <Box component="form" noValidate onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ mb: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Typography sx={{ mb: 2 }}>{t('pages:sign_up:labels:email')}</Typography>
            <Typography>{account?.email}</Typography>
          </Box>

          {/* テナント名（会社名） */}
          <Controller
            name="tenant_name"
            control={control}
            rules={validationRules.tenant_name}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:tenant_name')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {/* ConnectユーザID */}
          <Controller
            name="connect_user_id"
            control={control}
            rules={validationRules.connect_user_id}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:connect_user_id')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {/* ConnectインスタンスID */}
          <Controller
            name="connect_instance_id"
            control={control}
            rules={validationRules.connect_instance_id}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:connect_instance_id')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {/* AWSアカウントID */}
          <Controller
            name="aws_account_id"
            control={control}
            rules={validationRules.aws_account_id}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:aws_account_id')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {/* CDK用IAMロールARN */}
          <Controller
            name="iam_role_arn_for_cdk"
            control={control}
            rules={validationRules.iam_role_arn_for_cdk}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:iam_role_arn_for_cdk')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <img
            src={imageURL}
            alt="Button"
            style={{ cursor: 'pointer', width: '144px', height: '27px' }}
            onClick={openNewWindow}
          />
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" color="primary" variant="contained">
            {t('pages:sign_up:buttons:next')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
