import { useTranslation } from 'react-i18next';

import { RegisterForm } from '@/types';

export default function useAccount() {
  const { t } = useTranslation();

  // 初期値の設定
  const defaultValues: RegisterForm = {
    tenant_name: '',
    connect_user_id: '',
    connect_instance_id: '',
    aws_account_id: '',
    iam_role_arn_for_cdk: '',
  };

  // バリデーションルールの定義
  const validationRules = {
    tenant_name: {
      required: t('pages:sign_up:messages:tenant_name_required'),
      maxLength: { value: 50, message: t('pages:sign_up:messages:tenant_name_length') },
    },
    connect_user_id: {
      required: t('pages:sign_up:messages:connect_user_id_required'),
      maxLength: { value: 50, message: t('pages:sign_up:messages:connect_user_id_length') },
    },
    connect_instance_id: {
      required: t('pages:sign_up:messages:connect_instance_id_required'),
      maxLength: { value: 50, message: t('pages:sign_up:messages:connect_instance_id_length') },
    },
    aws_account_id: {
      required: t('pages:sign_up:messages:aws_account_id_required'),
      maxLength: { value: 50, message: t('pages:sign_up:messages:aws_account_id_length') },
    },
    iam_role_arn_for_cdk: {
      required: t('pages:sign_up:messages:iam_role_arn_for_cdk_required'),
      maxLength: { value: 100, message: t('pages:sign_up:messages:iam_role_arn_for_cdk_length') },
    },
  };

  return {
    defaultValues,
    validationRules,
  };
}
