import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from '@/components/Auth';
import RequireSignUp from '@/components/RequireSignUp';
import i18n from '@/locales/i18n';
import SignUpCompletion from '@/pages/account/SignUpCompletion';
import SignUpConfirmation from '@/pages/account/SignUpConfirmation';
import SignUpRegistration from '@/pages/account/SignUpRegistration';
import Callback from '@/pages/callback/index';

import SignUpCheckProvider from './components/SignUpCheckProvider';
import { ToastContextProvider } from './components/ToastProvider';
import { URL } from './types';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <ToastContextProvider>
          <Routes>
            <Route path={URL.CALLBACK} element={<Callback />} />
            <Route path={URL.SIGN_UP_COMPLETION} element={<SignUpCompletion />} />
            <Route path="/" element={<Auth />}>
              {/* Sign-in users */}
              <Route path="/" element={<RequireSignUp />}>
                <Route path="/" element={<Navigate to="/account/Completion" />} />
              </Route>
              {/* サインアップ */}
              <Route path="/" element={<SignUpCheckProvider />}>
                <Route path={URL.SIGN_UP_REGISTRATION} element={<SignUpRegistration />} />
                <Route path={URL.SIGN_UP_CONFIRMATION} element={<SignUpConfirmation />} />
              </Route>
            </Route>
          </Routes>
        </ToastContextProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
