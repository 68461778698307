import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';
import { URL } from '@/types';

export default function RequireSignUp() {
  const { account } = useAppSelector(authSelector);

  return account?.completed_sign_up ? <Outlet /> : <Navigate to={URL.SIGN_UP_REGISTRATION} />;
}
